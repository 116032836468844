import type { Vendor } from '@repo/db/schema'
import {
  Avatar,
  AvatarFallback,
  AvatarImage
} from '@repo/ui/components/Avatar.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'

export const VendorIcon = ({
  src,
  className,
  ...props
}: {
  src: Vendor['iconUrl']
} & ComponentProps<typeof Avatar>) => {
  return (
    <Avatar {...props} className={cn('rounded-md', className)}>
      <AvatarImage src={src} alt={'app icon'} className="object-contain" />
      <AvatarFallback className="bg-gray-200 text-primary/70 rounded-none">
        <Icon name="box-open" />
      </AvatarFallback>
    </Avatar>
  )
}
